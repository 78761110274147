import React from "react";
import "./promo.scss";
import Promoimage from "../../asset/hardsell-areum.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

const Promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62895352057994&text=Halo+Michael%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(promo)%20https://marketingareumparc.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="promo" className="container-promo">
      <div className=" container-listpromo">
        <div className="containercontent">
          <div className="judul-promo">PROMO KESELURUHAN AREUM PARC</div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Biaya BPHTB
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Biaya AJB</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free AC</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free CCTV </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Water Heater
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Torent Air
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button onClick={wapromo} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promo;
